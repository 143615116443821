import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getYoutubeContent,
  getLinkedInContent,
  getTwitterContent
} from "../actions/followUsContent";
import { getBasicFilesFromBytesB64 } from "../actions/filesFromBytes";
import FollowUs from "../components/FollowUs/FollowUs";
import globalConstants from "../constants/global-constants";

class FollowUsContainer extends Component {
  state = {
    linkedInData: "",
    youTubeData: "",
    twitterData: "",
    linkedInByteInfoData: "",
    youTubeByteInfoData: "",
    twitterByteInfoData: ""
  };

  UNSAFE_componentWillReceiveProps(nextprops) {
    this.getFollowUsData(nextprops);
  }

  getFollowUsData(nextprops) {
    this.setYouTubeImages(nextprops);
    this.setTwitterImages(nextprops);
    this.setLinkedInImages(nextprops);
  }

  componentDidMount() {
    this.getFollowUsData(this.props);
  }

  // Set Youtube
  setYouTubeImages(props) {
    const { isYouTubeFetching, youTubeData, isYouTubeError } = props;
    if (!isYouTubeError) {
      if (!isYouTubeFetching) {
        if (youTubeData) {
          if (youTubeData && youTubeData !== this.state.youTubeData) {
            if (youTubeData && youTubeData.length > 0) {
              this.getYouTubeByteData(props, youTubeData[0].field_image);
            }

            // this.setState({ youTubeData });
          }
        } else {
          this.props.dispatch(getYoutubeContent());
        }
      }
    }
  }

  setTwitterImages(props) {
    const { isTwitterFetching, twitterData, isTwitterError } = props;
    if (!isTwitterError) {
      if (!isTwitterFetching) {
        if (twitterData) {
          if (twitterData && twitterData !== this.state.twitterData) {
            // this.setState({ twitterData });
            if (twitterData && twitterData.length > 0) {
              this.getTwitterByteData(props, twitterData[0].field_image);
            }
          }
        } else {
          this.props.dispatch(getTwitterContent());
        }
      }
    }
  }

  setLinkedInImages(props) {
    const { isLinkedInFetching, linkedInData, isLinkedInError } = props;
    if (!isLinkedInError) {
      if (!isLinkedInFetching) {
        if (linkedInData) {
          if (linkedInData && linkedInData !== this.state.linkedInData) {
            // this.setState({ linkedInData });
            if (linkedInData && linkedInData.length > 0) {
              this.getLinkedInByteData(props, linkedInData[0].field_image);
            }
          }
        } else {
          this.props.dispatch(getLinkedInContent());
        }
      }
    }
  }

  getYouTubeByteData(props, code) {
    const { filesFromBytes, dispatch } = props;
    if (filesFromBytes) {
      const byteInfo = this.getData("YouTube", filesFromBytes.reponseB64);
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getBasicFilesFromBytesB64(req, "YouTube"));
      } else if (byteInfo && byteInfo.bytes) {
        this.setState({ youTubeByteInfoData: byteInfo });
      }
    }
  }

  getTwitterByteData(props, code) {
    const { filesFromBytes, dispatch } = props;
    if (filesFromBytes) {
      const byteInfo = this.getData("Twitter", filesFromBytes.reponseB64);
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getBasicFilesFromBytesB64(req, "Twitter"));
      } else if (byteInfo && byteInfo.bytes) {
        this.setState({ twitterByteInfoData: byteInfo });
      }
    }
  }

  getLinkedInByteData(props, code) {
    const { filesFromBytes, dispatch } = props;
    if (filesFromBytes) {
      const byteInfo = this.getData("LinkedIn", filesFromBytes.reponseB64);
      if (!byteInfo) {
        const req = {};
        req["filePath"] = code;
        dispatch(getBasicFilesFromBytesB64(req, "LinkedIn"));
      } else if (byteInfo && byteInfo.bytes) {
        this.setState({ linkedInByteInfoData: byteInfo });
      }
    }
  }

  getData(name, imagesData) {
    return imagesData.find(item => {
      return item[globalConstants.EMAIL] === name;
    });
  }

  render() {
    const { linkedInData, youTubeData, twitterData } = this.props;
    const {
      isLinkedInFetching,
      isYouTubeFetching,
      isTwitterFetching
    } = this.props;
    const {
      linkedInByteInfoData,
      youTubeByteInfoData,
      twitterByteInfoData
    } = this.state;

    return (
      <section>
        <FollowUs
          twitterData={twitterData}
          linkedInData={linkedInData}
          youTubeData={youTubeData}
          youTubeByteInfoData={youTubeByteInfoData}
          linkedInByteInfoData={linkedInByteInfoData}
          twitterByteInfoData={twitterByteInfoData}
          isLinkedInFetching={isLinkedInFetching}
          isYouTubeFetching={isYouTubeFetching}
          isTwitterFetching={isTwitterFetching}
        />
      </section>
    );
  }
}

const mapStateToProps = ({ followUsContent, filesFromBytes }) => {
  return { ...followUsContent, filesFromBytes };
};

export default connect(mapStateToProps)(FollowUsContainer);
