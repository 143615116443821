import { fetchGeneralContentUrl } from "../constants/microservice-helpers";
import http from "../constants/token-interceptors";

export const CPLOGO_REQUEST = "CPLOGO_REQUEST";
export const CPLOGO_SUCCESS = "CPLOGO_SUCCESS";
export const CPLOGO_FAILURE = "CPLOGO_FAILURE";

export const SERVEYOU_REQUEST = "SERVEYOU_REQUEST";
export const SERVEYOU_SUCCESS = "SERVEYOU_SUCCESS";
export const SERVEYOU_FAILURE = "SERVEYOU_FAILURE";

export const WELLDYNE_REQUEST = "WELLDYNE_REQUEST";
export const WELLDYNE_SUCCESS = "WELLDYNE_SUCCESS";
export const WELLDYNE_FAILURE = "WELLDYNE_FAILURE";

export function requestCPLogo() {
	return {
		type: CPLOGO_REQUEST,
		isCPLogoFetched: false,
	};
}

export function receiveCPLogo(cpLogoData) {
	return {
		type: CPLOGO_SUCCESS,
		isCPLogoFetched: true,
		cpLogoData,
	};
}

export function cplogoFailure(message) {
	return {
		type: CPLOGO_FAILURE,
		isCPLogoFetched: false,
		isCPLogoError: true,
		message,
	};
}

export function requestServeYou() {
	return {
		type: SERVEYOU_REQUEST,
		isServeYouFetched: false,
	};
}

export function receiveServeYou(serveYouData) {
	return {
		type: SERVEYOU_SUCCESS,
		isServeYouFetched: true,
		serveYouData,
	};
}

export function serveYouFailure(message) {
	return {
		type: SERVEYOU_FAILURE,
		isServeYouFetched: false,
		isServeYouError: true,
		message,
	};
}

export function requestWellDyne() {
	return {
		type: WELLDYNE_REQUEST,
		isWellDyneFetched: false,
	};
}

export function receiveWellDyne(wellDyneData) {
	return {
		type: WELLDYNE_SUCCESS,
		isWellDyneFetched: true,
		wellDyneData,
	};
}

export function WellDyneFailure(message) {
	return {
		type: WELLDYNE_FAILURE,
		isWellDyneFetched: false,
		isWellDyneError: true,
		message,
	};
}

export function getCPLogoContent() {
	return (dispatch) => {
		dispatch(requestCPLogo());
		const url = fetchGeneralContentUrl("/cms/getCPLogo");
		http
			.get(url)
			.then((response) => {
				const data = response.data;
				if (response.status !== 200) {
					dispatch(cplogoFailure(data.message));
					return Promise.reject(data);
				} else {
					dispatch(receiveCPLogo(data));
					return data;
				}
			})
			.catch(function (error) {
				dispatch(cplogoFailure(error));
			});
	};
}

export function getWellDyneContent() {
	return (dispatch) => {
		dispatch(requestWellDyne());
		const url = fetchGeneralContentUrl("/cms/getWellDyneLogo");
		http
			.get(url)
			.then((response) => {
				const data = response.data;
				if (response.status !== 200) {
					dispatch(WellDyneFailure(data.message));
					return Promise.reject(data);
				} else {
					dispatch(receiveWellDyne(data));
					return data;
				}
			})
			.catch(function (error) {
				dispatch(WellDyneFailure(error));
			});
	};
}

export function getServeYouContent() {
	return (dispatch) => {
		dispatch(requestServeYou());
		const url = fetchGeneralContentUrl("/cms/getServeYouLogo");
		http
			.get(url)
			.then((response) => {
				const data = response.data;
				if (response.status !== 200) {
					dispatch(serveYouFailure(data.message));
					return Promise.reject(data);
				} else {
					dispatch(receiveServeYou(data));
				}
				return data;
			})
			.catch(function (error) {
				dispatch(serveYouFailure(error));
			});
	};
}
