import {
  PAGE_LOAD_NAME,
  PAGE_LINK_NAME,
  VIDEO_ACTION,
  INVOICE_FILTER,
} from "../actions/tealium";

const defaultPageDataLayerState = {
  content: {
    pageName: "",
    siteSectionL1: "",
    siteSectionL2: "",
    siteSectionL3: "",
    siteSectionL4: "",
    tabName: "",
    businessUnit: "optum",
    website: "rx-client",
    language: "en",
    siteErrorCode: "",
    siteErrorType: "",
    siteErrorFields: "",
    splitordercount: "",
    holdordercount: "",
    splitorders: "",
    holdorders: "",
    mobileapp: "false",
  },
  user: {
    loginStatus: "not logged in",
    cagID: "",
    optumID: "",
    userType: "",
  },
  actions: {
    linkName: "",
    linkType: "",
    exitLinkName: "",
  },
  video: {
    name: "",
    length: "",
    status: "",
  },
  search: {
    searchTerm: "",
    searchFilters: "",
    searchResults: "",
  },
};

const usrLoginTime = "user.loginTime";
const usrSessionTime = "user.sessionTime";
const usrAction = "user.action";
let dataLayerobj = {};
var pixelObj = JSON.parse(sessionStorage.getItem("pxCustomData"));
let pageDataLayerCurrentState;

function tealiumDataLayer(state = defaultPageDataLayerState, action) {
  pixelObj = JSON.parse(sessionStorage.getItem("pxCustomData"));
  pageDataLayerCurrentState = { ...state };

  var customObject = { data: {} };
  switch (action && action.type) {
    // Page Load name
    case PAGE_LOAD_NAME:
      return tealiumDataPageLoadName(customObject, action);

    // Page Link name
    case PAGE_LINK_NAME:
      return tealiumDataPageLinkName(customObject, action);

    // Video Action
    case VIDEO_ACTION:
      return tealiumDataVideoAction(action);

    // Invoice Action
    case INVOICE_FILTER:
      return tealiumDataInvoice(action);
    default:
      return state;
  }
}

function tealiumDataPageLoadName(customObject, action) {
  if (window.adobeDataLayer && action.payload && action.payload.content && action.payload.user) {
    var dataLayerobj = {
      data: {
        content: {
          businessUnit: action.payload.content.businessUnit,
          pageName: action.payload.content.pageName,
          siteSectionL1: action.payload.content.siteSectionL1,
          siteSectionL2: action.payload.content.siteSectionL2,
          siteSectionL3: action.payload.content.siteSectionL3,
          siteSectionL4: action.payload.content.siteSectionL4,
          website: action.payload.content.website,
          language: action.payload.content.language,
          newsArticle: action.payload.content.newsArticle,
        },
        user: {
          clientID: action.payload.user.clientID,
          userID: action.payload.user.userID,
          loginStatus: action.payload.user.loginStatus,
          userType: action.payload.user.userType,
        }
      },
    };
    if (pixelObj && pixelObj[usrLoginTime]) {
      const currentTime = new Date().getTime();
      pixelObj[usrSessionTime] = currentTime - pixelObj[usrLoginTime];
      pixelObj[usrAction] = "pageLoad";
      pixelObj["user.clientID"] =
        action &&
        action.payload &&
        action.payload.user &&
        action.payload.user.clientID
          ? action.payload.user.clientID
          : "";
    }
    customObject.data = {
      ...dataLayerobj.data,
      ...pixelObj?.data,
    };

    window.adobeDataLayer.push({ ...customObject, event: "pageview" });
    // window.utag.view(dataLayerobj);
  }

  sendPixelObject(customObject.data);
  if (
    window &&
    window.pxSendCustomData &&
    customObject &&
    action &&
    action.payload &&
    action.payload.content &&
    action.payload.content.pageName
  ) {
    if (!pixelObj || pixelObj === "null") {
      const signInOBJ = {
        data: {
          businessUnit: "optum",
          language: "en",
          pageName: "optum:rx-client:Sign in",
          website: "rx-client",
          "user.loginStatus": "not logged in",
          "user.userID": "",
          "user.userType": "",
        },
      };
      sendPixelObject(signInOBJ.data);
      window.pxSendCustomData(signInOBJ.data);
    } else {
      sessionStorage.setItem("pxCustomData", JSON.stringify(pixelObj));
      window.pxSendCustomData(customObject.data);
    }
  }
  pageDataLayerCurrentState = {
    ...pageDataLayerCurrentState,
    content: {
      ...pageDataLayerCurrentState.content,
      ...action.payload.content,
    },
    user: {
      ...pageDataLayerCurrentState.user,
      ...action.payload.user,
    },
  };
  return pageDataLayerCurrentState;
}

function tealiumDataPageLinkName(customObject, action) {
  if (window.adobeDataLayer) {
    dataLayerobj = {
      data: {
        actions: {
          linkName: action.linkName,
          linkType: action.linkType,
          exitLinkName: action.exitLinkName,
        },
      },
    };
    if (pixelObj && pixelObj[usrLoginTime]) {
      const currentTime = new Date().getTime();
      pixelObj[usrAction] = "exitLink";
      pixelObj[usrSessionTime] = currentTime - pixelObj[usrLoginTime];
    }
    customObject = { ...dataLayerobj.data, ...pixelObj };
    // window.utag.view(dataLayerobj);
    window.adobeDataLayer.push({ ...dataLayerobj, event: "pageview" });
  }
  sendPixelObject(customObject);
  if (window && window.pxSendCustomData && customObject && action.linkName) {
    sessionStorage.setItem("pxCustomData", JSON.stringify(pixelObj));
    window.pxSendCustomData(customObject);
  }

  pageDataLayerCurrentState = {
    ...pageDataLayerCurrentState,
    content: {
      ...pageDataLayerCurrentState.content,
    },
    user: {
      ...pageDataLayerCurrentState.user,
    },
    actions: {
      ...pageDataLayerCurrentState.actions,
    },
  };
  return pageDataLayerCurrentState;
}
function tealiumDataVideoAction(action) {
  if (typeof window !== "undefined" && window.adobeDataLayer) {
    window.utag_data = {
      ...window.utag_data,
      data: {
        video: {
          name: action.name,
          length: action.length,
          status: action.status,
          videoTimeWatched: action.videoTimeWatched,
        },
      },

      tealium_event: action.event,
    };
    window.adobeDataLayer.push({ ...window.utag_data, event: "linktrack" });
    // window.utag.link(window.utag_data);
  }

  pageDataLayerCurrentState = {
    ...pageDataLayerCurrentState,
    content: {
      ...pageDataLayerCurrentState.content,
    },
    user: {
      ...pageDataLayerCurrentState.user,
    },
    actions: {
      ...pageDataLayerCurrentState.actions,
    },
    video: {
      ...pageDataLayerCurrentState.video,
    },
  };
  return pageDataLayerCurrentState;
}

function tealiumDataInvoice(action) {
  if (window.adobeDataLayer) {
    dataLayerobj = {
      data: {
        actions: {
          "search.searchTerm": action.searchTerm,
          "search.searchType": "invoices",
          "search.searchFilters": action.searchFilters,
          "search.searchResults": action.searchResults,
        },
      },

      tealium_event: "invoicesAndFinancialReportsSearch",
    };
    // window.utag.view(dataLayerobj);
    window.adobeDataLayer.push({ ...dataLayerobj, event: "pageview" });
    // window.utag.link(dataLayerobj);
    window.adobeDataLayer.push({ ...dataLayerobj, event: "linktrack" });
  }
  if (pixelObj && pixelObj[usrLoginTime]) {
    const currentTime = new Date().getTime();
    pixelObj[usrSessionTime] = currentTime - pixelObj[usrLoginTime];
    pixelObj[usrAction] = "invoicesAndFinancialReportsSearch";
  }
  const pixelCustomObject = { ...dataLayerobj.data, ...pixelObj };
  sendPixelObject(pixelCustomObject);
  if (
    window &&
    window.pxSendCustomData &&
    pixelCustomObject &&
    action.searchTerm
  ) {
    sessionStorage.setItem("pxCustomData", JSON.stringify(pixelObj));
    window.pxSendCustomData(pixelCustomObject);
  }

  pageDataLayerCurrentState = {
    ...pageDataLayerCurrentState,
    content: {
      ...pageDataLayerCurrentState.content,
    },
    user: {
      ...pageDataLayerCurrentState.user,
    },
    actions: {
      ...pageDataLayerCurrentState.actions,
    },
    video: {
      ...pageDataLayerCurrentState.video,
    },
    search: {
      ...pageDataLayerCurrentState.search,
    },
  };
  return pageDataLayerCurrentState;
}

function sendPixelObject(pixelCustomObject) {
  pixelCustomObject["namespace"] = process.env.REACT_APP_NAMESPACE;
  for (const item in pixelCustomObject) {
    if (pixelCustomObject.hasOwnProperty(item)) {
      if (pixelCustomObject[item] === "") {
        delete pixelCustomObject[item];
      }
    }
  }
}

export default tealiumDataLayer;
